import * as React from "react";
import DefaultLayout from "../layouts/default";
import { Helmet } from "react-helmet";
import Leaderboard from "../components/Learderboard";
import Container from "../components/Container";
import CarSearch from "../components/CarSearchMinimal";
import News from "../components/News";
import CallToAction from "../components/CallToAction";
import SiteTeaser from "../components/SiteTeaser";
import CarOverview from "../components/CarOverview";
import ContentBlock from "../components/ContentBlock";
import LocationsWithMap from "../components/LocationsWithMap";
import CarSearchMinimal from "../components/CarSearchMinimal";
import favicon from "../assets/favicon.ico";

interface CMSProps {
  pageContext: {
    site: any;
    locations: any;
    vehicleCategories: any;
    apiResponse: any;
  };
}

const Cms: React.FC<CMSProps> = (props) => {
  const {
    pageContext: {
      site: { title, seoTitle, seoDescription, main_content },
      locations,
      vehicleCategories,
      apiResponse,
    },
  } = props;
  let pageTitle = title!;
  if (seoTitle) {
    pageTitle = seoTitle;
  }
  return (
    <DefaultLayout>
      <Helmet>
        <meta charset="utf-8" />
        <title>{pageTitle}</title>
        <meta name="description" content={seoDescription} />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <link rel="icon" type="image/x-icon" href={favicon} />
      </Helmet>
      <Container>
        {main_content &&
          main_content.map((contentBlock: any, index: any) => {
            const { blockType, children } = contentBlock;
            if (blockType === "CMS_main_content_leaderboard_BlockType") {
              return (
                <Leaderboard
                  key={index}
                  mobileReverseOrder={contentBlock.mobileReverseOrder}
                  internalLink={
                    contentBlock.internalLink.length &&
                    "/" + contentBlock.internalLink[0].uri
                  }
                  externalLink={contentBlock.externalLink}
                  children={children}
                />
              );
            }
            if (blockType === "CMS_main_content_carSearch_BlockType") {
              return (
                <CarSearchMinimal
                  key={index}
                  locations={locations}
                  vehicleCategories={vehicleCategories}
                />
              );
            }
            if (blockType === "CMS_main_content_news_BlockType") {
              return <News key={index} />;
            }
            if (blockType === "CMS_main_content_callToAction_BlockType") {
              return (
                <CallToAction
                  key={index}
                  title={contentBlock.htmlText}
                  link={contentBlock.internalLink[0].uri}
                />
              );
            }
            if (blockType === "CMS_main_content_siteTeaser_BlockType") {
              return (
                <SiteTeaser
                  key={index}
                  title={contentBlock.teaserTitle}
                  text={contentBlock.text}
                  link={
                    contentBlock.internalLink[0] &&
                    contentBlock.internalLink[0].uri
                  }
                />
              );
            }
            if (blockType === "CMS_main_content_carOverview_BlockType") {
              return <CarOverview key={index} models={apiResponse} />;
            }
            if (blockType === "CMS_main_content_contentBlock_BlockType") {
              return <ContentBlock children={children} key={index} />;
            }
            if (blockType === "CMS_main_content_locationsWithMap_BlockType") {
              return <LocationsWithMap locations={locations} key={index} />;
            }
            return <div key={index}>This is an unknown block: {blockType}</div>;
          })}
      </Container>
    </DefaultLayout>
  );
};

export default Cms;
