import * as React from "react";
import styled from "styled-components";

import { breakpoints, colors, dimensions, widths } from "../styles/variables";

export const StyledLeaderboard = styled.a`
  text-decoration: none;
  display: block;
  color: ${colors.brandBlue};
  display: flex;
  flex-direction: column;

  a {
    display: block;
    color: ${colors.brandBlue};
    transition: all 0.2s;
    font-size: 18px;
    margin-top: 24px;
  }

  @media (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    background-color: ${colors.brandBlue};
    height: 402px;
    transition: all 0.2s;
    color: ${colors.white};
    &:hover {
      background-color: ${colors.grey};
      color: ${colors.brandBlue};
      a {
        color: ${colors.brandBlue};
      }
    }

    a {
      color: ${colors.white};
    }
  }
`;

export const StyledLeaderboardLeft = styled.div`
  img {
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.md}px) {
    order: ${(props) => props.order};
  }
  @media (min-width: ${breakpoints.md}px) {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      padding: 20px 43px 20px 176px;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;
export const StyledLeaderboardRight = styled.div`
  img {
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.md}px) {
    order: ${(props) => props.order};
  }
  @media (min-width: ${breakpoints.md}px) {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      padding: 20px 176px 20px 43px;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
`;

export const StyledLeaderboardText = styled.span`
  display: block;
  padding: 20px 36px;
`;

export const StyledLeaderboardTextTitle = styled.h1`
  font-size: ${dimensions.fontSize.regular}px;
  font-weight: ${dimensions.fontWeight.regular};
  padding-bottom: 12px;
  @media (min-width: ${breakpoints.md}px) {
    font-size: ${dimensions.fontSize.large}px;
  }
`;

export const StyledLeaderboardTextText = styled.div`
  font-size: ${dimensions.fontSize.large}px;
  font-weight: ${dimensions.fontWeight.bold};
  @media (min-width: ${breakpoints.md}px) {
    font-size: ${dimensions.fontSize.xl}px;
  }
`;

interface LeaderboardProps {
  children: any;
  mobileReverseOrder: boolean;
  internalLink?: string;
  externalLink?: string;
}

const Leaderboard: React.FC<LeaderboardProps> = ({
  children,
  mobileReverseOrder,
  internalLink,
  externalLink,
}) => (
  <StyledLeaderboard
    href={externalLink ? externalLink : internalLink ? internalLink : null}
    target={externalLink ? "_blank" : null}
  >
    {children.map((contentBlock: any, index: any) => {
      const { blockType, children } = contentBlock;
      if (blockType === "CMS_main_content_left_BlockType") {
        if (contentBlock.images.length) {
          return (
            <StyledLeaderboardLeft
              key={index}
              order={mobileReverseOrder ? 2 : 1}
            >
              <img
                src={`${contentBlock.images[0].url}?q=80&auto=format,compress`}
                alt={contentBlock.images[0].title}
              />
            </StyledLeaderboardLeft>
          );
        }
        return (
          <StyledLeaderboardLeft key={index} order={mobileReverseOrder ? 2 : 1}>
            <StyledLeaderboardText>
              <StyledLeaderboardTextTitle
                dangerouslySetInnerHTML={{
                  __html: contentBlock.leaderboardTitle,
                }}
              />
              <StyledLeaderboardTextText
                dangerouslySetInnerHTML={{ __html: contentBlock.text }}
              />
            </StyledLeaderboardText>
          </StyledLeaderboardLeft>
        );
      }
      if (blockType === "CMS_main_content_right_BlockType") {
        if (contentBlock.images.length) {
          return (
            <StyledLeaderboardRight
              key={index}
              order={mobileReverseOrder ? 1 : 2}
            >
              <img
                src={contentBlock.images[0].url + "?q=80&auto=format,compress"}
                alt={contentBlock.images[0].title}
              />
            </StyledLeaderboardRight>
          );
        }
        return (
          <StyledLeaderboardRight
            key={index}
            order={mobileReverseOrder ? 1 : 2}
          >
            <StyledLeaderboardText>
              <StyledLeaderboardTextTitle
                dangerouslySetInnerHTML={{
                  __html: contentBlock.leaderboardTitle,
                }}
              />
              <StyledLeaderboardTextText
                dangerouslySetInnerHTML={{ __html: contentBlock.text }}
              />
            </StyledLeaderboardText>
          </StyledLeaderboardRight>
        );
      }
      return <>This is an unknown leaderboard block: {blockType}</>;
    })}
  </StyledLeaderboard>
);

export default Leaderboard;
