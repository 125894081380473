import * as React from "react";
import styled from "styled-components";

import { breakpoints, colors, dimensions, widths } from "../styles/variables";
import { StyledButton } from "./CallToAction";

const StyledSiteTeaser = styled.div`
  font-size: ${dimensions.fontSize.medium}px;
  line-height: ${dimensions.lineHeight.medium}px;
  color: ${colors.brandBlue};
  padding: 20px 36px;
  p {
    margin: 0;
  }

  @media (min-width: ${breakpoints.md}px) {
    padding: 42px 180px 52px 180px;
  }
`;

const StyledTitle = styled.div`
  font-size: ${dimensions.fontSize.regular}px;
  line-height: ${dimensions.lineHeight.regular}px;
  padding: 20px 0 0 0;
`;

const StyledText = styled.div`
  font-size: ${dimensions.fontSize.large}px;
  line-height: ${dimensions.lineHeight.large}px;
  padding: 0 0 20px 0;
  font-weight: 800;
  a {
    color: ${colors.brandBlue};
  }
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

interface SiteTeaserProps {
  title: string;
  text: string;
  link?: string;
}

const SiteTeaser: React.FC<SiteTeaserProps> = ({ title, text, link }) => (
  <StyledSiteTeaser>
    <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
    <StyledText dangerouslySetInnerHTML={{ __html: text }} />

    {link && (
      <ButtonWrapper>
        <StyledButton href={link}>Mehr lesen...</StyledButton>
      </ButtonWrapper>
    )}
  </StyledSiteTeaser>
);
export default SiteTeaser;
