import * as React from "react";
import styled from "styled-components";

import mapmarker from "../assets/mapmarker.png";
import mapmarkerhover from "../assets/mapmarkerhover.png";

import { Marker } from "react-map-gl";

import { colors } from "../styles/variables";

const StyledMarkerContent = styled.div`
  text-align: center;

  &:hover {
    .StyledMarkerImgOrig {
      display: none;
    }
    .StyledMarkerImgHover {
      display: inline;
    }

    .StyledMarkerText {
      opacity: 1;
    }
  }

  .StyledMarkerImgOrig {
    width: 50px !important;
  }
  .StyledMarkerImgHover {
    width: 50px !important;
    display: none;
  }

  .StyledMarkerText {
    opacity: 0;
    transition: all 0.2s;
    background-color: ${colors.brandBlue};
    padding: 5px 10px;
    border-radius: 10px;
    color: white;
  }
`;

interface MapMarkerProps {
  longitude: any;
  latitude: any;
  title: any;
  locationId: any;
}

const MapMarker: React.FC<MapMarkerProps> = ({
  longitude,
  latitude,
  title,
  locationId,
}) => (
  <Marker longitude={longitude} latitude={latitude}>
    <StyledMarkerContent>
      <a href={`#location${locationId}`}>
        <img className="StyledMarkerImgOrig" src={mapmarker} alt="marker" />
        <img
          className="StyledMarkerImgHover"
          src={mapmarkerhover}
          alt="marker"
        />
        <div className="StyledMarkerText">{title}</div>
      </a>
    </StyledMarkerContent>
  </Marker>
);

export default MapMarker;
