import * as React from "react";
import styled from "styled-components";

import { breakpoints, colors, dimensions, widths } from "../styles/variables";

const StyledContentBlock = styled.div`
  display:flex;
  flex-direction:column;
  font-size:${dimensions.fontSize.medium}px;
  line-height:${dimensions.lineHeight.medium}px;
  background-color:#E5E5E5;
  padding:30px 37px;

 
  @media (min-width:${breakpoints.md}px){
    flex-direction:row;
    flex-wrap: wrap;
    padding:42px 180px;
  }
`;


const StyledSidebar = styled.div`
  order:2;
  font-size:${dimensions.fontSize.medium}px;
  line-height:${dimensions.lineHeight.medium}px;

 
  @media (min-width:${breakpoints.md}px){
    order:1;
    margin-right:42px;
    width:220px;
  }
`;

const StyledMainContent = styled.div`
  order:1;
  font-size:${dimensions.fontSize.medium}px;
  line-height:${dimensions.lineHeight.medium}px;

 
  @media (min-width:${breakpoints.md}px){
    order:2;

    width:744px;
  }
`;

interface ContentBlockProps {
  children:any;
}

const ContentBlock: React.FC<ContentBlockProps> = ({children}) => {
  return(
    <StyledContentBlock>
      <StyledSidebar>
        {children.map((contentBlock:any) => {
          const {blockType, htmlText} = contentBlock;
          if(blockType === "CMS_main_content_sidebar_BlockType"){
            return <div dangerouslySetInnerHTML={{__html: htmlText}} />
          }
        })}
      </StyledSidebar>
      <StyledMainContent>
        {children.map((contentBlock:any) => {
          const {blockType, htmlText} = contentBlock;
          if(blockType === "CMS_main_content_mainContent_BlockType"){
            return <div dangerouslySetInnerHTML={{__html: htmlText}} />
          }
        })}
      </StyledMainContent>
    </StyledContentBlock>
  )
};

export default ContentBlock;
