import * as React from "react";
import styled from "styled-components";
var Carousel = require("react-responsive-carousel").Carousel;
import { StaticQuery, graphql, Link } from "gatsby";
import {
  StyledLeaderboard,
  StyledLeaderboardLeft,
  StyledLeaderboardRight,
  StyledLeaderboardText,
  StyledLeaderboardTextText,
  StyledLeaderboardTextTitle,
} from "./Learderboard";
import { breakpoints, colors } from "../styles/variables";

const StyledCarousel = styled.div`
  %arrows {
    @include transition(all, 0.25s, ease-in);
    @include opacity(0.4);
    position: absolute;
    z-index: 2;
    top: 20px;
    background: none;
    border: 0;
    font-size: 32px;
    cursor: pointer;

    &:focus,
    &:hover {
      @include opacity(1);
    }

    &.control-arrow {
      &:before {
        margin: 0 5px;
        display: inline-block;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        content: "";
      }
    }

    &.control-disabled {
      @include opacity(0);
      cursor: inherit;
      display: none;
    }

    &.control-prev {
      left: 0;
      &:before {
        border-right: 8px solid #fff;
      }
    }

    &.control-next {
      right: 0;
      &:before {
        border-left: 8px solid #fff;
      }
    }
  }

  .carousel-root {
    outline: none;
  }

  .carousel {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      display: inline-block;
      pointer-events: none;
    }

    .carousel {
      position: relative;
    }

    .control-arrow {
      display: none;
    }

    .thumbs-wrapper {
      display: none;
    }

    &.carousel-slider {
      position: relative;
      margin: 0;
      overflow: hidden;
    }

    .slider-wrapper {
      overflow: hidden;
      margin: auto;
      width: 100%;

      &.axis-horizontal {
        .slider {
          -ms-box-orient: horizontal;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -moz-flex;
          display: -webkit-flex;
          display: flex;

          .slide {
            flex-direction: column;
            flex-flow: column;
          }
        }
      }

      &.axis-vertical {
        -ms-box-orient: horizontal;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;

        .slider {
          -webkit-flex-direction: column;
          flex-direction: column;
        }
      }
    }

    .slider {
      margin: 0;
      padding: 0;
      position: relative;
      list-style: none;
      width: 100%;

      &.animated {
        // activate hardware accelerated animations using translate property
        // @include enhanceAnimations();
      }
    }

    .slide {
      min-width: 100%;
      margin: 0;
      position: relative;
    }

    .control-dots {
      position: absolute;
      bottom: 0;
      margin: 10px 0 3px 0;
      padding: 0;
      text-align: center;
      width: 100%;
      z-index: 1;

      @media (min-width: ${breakpoints.md}px) {
        margin: 10px 0 10px 65px;
      }

      .dot {
        background-color: transparent;
        border-radius: 50%;
        border: 2px solid ${colors.brandBlue};
        width: 10px;
        height: 10px;
        cursor: pointer;
        display: inline-block;
        margin: 0 8px;

        &.selected,
        &:hover {
          // do something
          background-color: ${colors.brandBlue};
        }
        @media (min-width: ${breakpoints.md}px) {
          border: 2px solid white;
          &.selected,
          &:hover {
            // do something
            background-color: white;
          }
        }
      }
    }

    .carousel-status {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      font-size: 10px;
      text-shadow: 1px 1px 1px rgba(#000, 0.9);
      color: #fff;
    }

    &:hover {
      .slide .legend {
        opacity: 1;
      }
    }
  }
`;

const News: React.FC = () => (
  <StyledCarousel>
    <StaticQuery
      query={graphql`
        query NewsQUery {
          cms {
            newsEntries {
              ... on CMS_news_default_Entry {
                id
                images {
                  url
                  title
                }
                slug
                title
                text
                expiryDate
                main_content {
                  blockType: __typename
                  ... on CMS_main_content_leaderboard_BlockType {
                    mobileReverseOrder
                    internalLink {
                      uri
                    }
                    children {
                      id
                      blockType: __typename
                      ... on CMS_main_content_left_BlockType {
                        leaderboardTitle
                        text
                        images {
                          url
                          title
                        }
                      }
                      ... on CMS_main_content_right_BlockType {
                        leaderboardTitle
                        text
                        images {
                          url
                          title
                        }
                      }
                    }
                  }
                  ... on CMS_main_content_contentBlock_BlockType {
                    children {
                      id
                      blockType: __typename
                      ... on CMS_main_content_sidebar_BlockType {
                        htmlText
                      }
                      ... on CMS_main_content_mainContent_BlockType {
                        htmlText
                      }
                    }
                  }
                  ... on CMS_main_content_siteTeaser_BlockType {
                    teaserTitle
                    text
                    internalLink {
                      uri
                    }
                  }
                  ... on CMS_main_content_callToAction_BlockType {
                    htmlText
                    internalLink {
                      uri
                    }
                  }
                  ... on CMS_main_content_locationsWithMap_BlockType {
                    leaderboardTitle
                    text
                  }
                }
              }
            }
          }
        }
      `}
      render={(data: any) => (
        <>
          {data.cms.newsEntries.length > 0 && (
            <Carousel
              showArrows={true}
              autoPlay
              interval={3000}
              infiniteLoop
              showThumbs={false}
            >
              {data.cms.newsEntries.map((newsEntry: any, index: any) => (
                <StyledLeaderboard key={index} href={`/news/${newsEntry.slug}`}>
                  <StyledLeaderboardLeft>
                    {newsEntry.images.length && (
                      <img
                        src={`${newsEntry.images[0].url}?q=80&auto=format,compress`}
                        alt={newsEntry.images[0].title}
                      />
                    )}
                  </StyledLeaderboardLeft>
                  <StyledLeaderboardRight>
                    <StyledLeaderboardText>
                      <StyledLeaderboardTextTitle>
                        {newsEntry.text}
                      </StyledLeaderboardTextTitle>
                      <StyledLeaderboardTextText>
                        {newsEntry.title}
                      </StyledLeaderboardTextText>
                    </StyledLeaderboardText>
                  </StyledLeaderboardRight>
                </StyledLeaderboard>
              ))}
            </Carousel>
          )}
        </>
      )}
    />
  </StyledCarousel>
);
/*


                  <div>
                    <img src={newsEntry.images[0].url} />
                    <p className="legend">{newsEntry.title} {newsEntry.text}</p>
                  </div>

*/
export default News;
