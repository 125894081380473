import * as React from "react";
import styled from "styled-components";

import { breakpoints } from "../styles/variables";
import Container from "./Container";
import {
  Centering,
  Flex,
  ModelImage,
  ModelText,
  ModelTitle,
  StyledModel,
} from "../layouts/models";
import Model from "../layouts/model";

const StyledCarOverview = styled.div`
  @media (min-width: ${breakpoints.md}px) {
  }
`;

interface CarOverviewProps {
  models: any;
}

const CarOverview: React.FC<CarOverviewProps> = ({ models }) => {
  const allInModels = models.data.filter(
    (model: any) => model.attributes.allInPrices !== undefined
  );
  return (
    <StyledCarOverview>
      <Container>
        <Centering>
          <Flex>
            {allInModels.map((model: any, index: any) => {
              const withoutZero: any = [];
              model.attributes.allInPrices.prices.map((price: any) => {
                if (price.price !== 0) {
                  withoutZero.push(price);
                }
              });
              const cheapestPrice = withoutZero.sort(
                (a: any, b: any) => parseFloat(a.price) - parseFloat(b.price)
              );
              return (
                <StyledModel href={`/fahrzeug/allIncl/${model.id}`} key={index}>
                  {model.attributes.images.length > 0 && (
                    <ModelImage
                      src={`${model.attributes.images[0].fullsize}?auto=compress,format&fit=max&w=440&h=320`}
                      alt=""
                    />
                  )}
                  <ModelText>
                    <ModelTitle>{model.attributes.model}</ModelTitle>
                    <br />
                    {model.relationships.vehicleCategory.data.attributes.title}
                    <br />
                    {cheapestPrice.length > 0 && (
                      <>ab {cheapestPrice[0].price.toFixed(2)} CHF / Monat</>
                    )}
                  </ModelText>
                </StyledModel>
              );
            })}
          </Flex>
        </Centering>
      </Container>
    </StyledCarOverview>
  );
};

export default CarOverview;
